import { createContext, useContext } from 'react'

const BannerContext = createContext({
  id: '',
  Title: '',
  trackingData: null,
  isHero: false,
})

export const BannerContextProvider = BannerContext.Provider

export const useBannerContext = () => useContext(BannerContext) || {}
