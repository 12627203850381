import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { styled } from '@mui/material/styles'
import EmailSubscribeForm from './EmailSuscribe'
import StrapiMarkdown from '../Markdown'
import LongForm from './LongForm'

const EmailSuscribeFormWrapper = styled('div')(({ theme, DesktopSpacing, MobileSpacing }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: DesktopSpacing?.TopPadding ?? 0,
  paddingBottom: DesktopSpacing?.BottomPadding ?? 0,
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '54px',
    paddingTop: MobileSpacing?.TopPadding ?? 0,
    paddingBottom: MobileSpacing?.BottomPadding ?? 0,
  },
}))

const StyledStrapiMarkdown = styled(StrapiMarkdown)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
}))

const StrapiSignUpForm = ({ data }) => {
  const {
    WelcomeMessage,
    ConfirmationMessage,
    ExpirationMessage,
    SegmentSubSourceCode,
    StartDateAndTime,
    EndDateAndTime,
    DesktopSpacing,
    MobileSpacing,
    Style,
    ...largerFormData
  } = data?.SignUpForm || {}

  const currentSignUpForm = new Date(StartDateAndTime) < new Date() && new Date(EndDateAndTime) > new Date()

  if (!currentSignUpForm) {
    return <StyledStrapiMarkdown gridWrapper={false} data={{ Markdown: ExpirationMessage }} />
  }

  if (Style === 'Simple') {
    return (
      <EmailSuscribeFormWrapper DesktopSpacing={DesktopSpacing} MobileSpacing={MobileSpacing}>
        <EmailSubscribeForm
          subsourcecode={SegmentSubSourceCode}
          successMessage={ConfirmationMessage}
          welcomeMessage={WelcomeMessage}
        />
      </EmailSuscribeFormWrapper>
    )
  }
  if (Style === 'Long Form') {
    return (
      <EmailSuscribeFormWrapper DesktopSpacing={DesktopSpacing} MobileSpacing={MobileSpacing}>
        <LongForm
          data={largerFormData}
          successMessage={ConfirmationMessage}
          segmentSubsourceCode={SegmentSubSourceCode}
        />
      </EmailSuscribeFormWrapper>
    )
  }
  return null
}

export default StrapiSignUpForm

StrapiSignUpForm.propTypes = {
  data: PropTypes.object,
}

export const signUpFormFragment = graphql`
  fragment StrapiPageSignUpFormFragment on PageSignUpForm {
    SignUpForm {
      StartDateAndTime
      EndDateAndTime
      SegmentSubSourceCode
      SegmentSourceCode
      WelcomeMessage {
        data {
          childMarkdownRemark {
            html
          }
        }
      }
      ExpirationMessage {
        data {
          childMarkdownRemark {
            html
          }
        }
      }
      Style
      ShowFirstName
      RequireFirstName
      ShowLastName
      RequireLastName
      ShowAddressLine1
      RequireAddressLine1
      ShowAddressLine2
      RequireAddressLine2
      ShowCity
      RequireCity
      ShowState
      RequireState
      ShowZipCode
      RequireZipCode
      MobileSpacing {
        BottomPadding
        TopPadding
      }
      DesktopSpacing {
        BottomPadding
        TopPadding
      }
      ConfirmationMessage {
        data {
          childMarkdownRemark {
            html
          }
        }
      }
      Title
      ButtonBackgroundColor {
        ColorHex
      }
      ButtonTextColor {
        ColorHex
      }
    }
  }
`
