import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { useTest } from '@hooks/useTest'
import HeadingAndSeeAllLink from '../../../@rtg2022/components/molecules/HeadingAndSeeAllLink/HeadingAndSeeAllLink'

const StrapiHeader = ({ data, headingStyles }) => {
  const { generateTestId } = useTest()
  const Header = data?.Header || data
  const { Description, Heading, SeeAllLink, SubheadingMobile, HeaderVariants, DescriptionMobile } = Header ?? {}

  let subheadingMobileCustom = SubheadingMobile
  if (Description && SeeAllLink && !SubheadingMobile) {
    subheadingMobileCustom = 'link'
  }

  return (
    <HeadingAndSeeAllLink
      title={Heading}
      viewAllLink={SeeAllLink}
      description={Description}
      subheadingMobile={subheadingMobileCustom}
      headingStyles={headingStyles ?? { marginBottom: '25px', fontSize: '25px', fontSizeMobile: '20px' }}
      headingVariants={HeaderVariants}
      descriptionMobile={DescriptionMobile ?? null}
      testId={generateTestId('component', 'header')}
    />
  )
}

StrapiHeader.propTypes = {
  data: PropTypes.object.isRequired,
  headingStyles: PropTypes.shape({
    fontSize: PropTypes.string,
    fontSizeMobile: PropTypes.string,
    fontWeight: PropTypes.number,
    letterSpacing: PropTypes.string,
    marginBottom: PropTypes.string,
  }),
}

export const StrapiHeaderFragment = graphql`
  fragment StrapiHeaderFragment on BaseHeader {
    Description
    Heading
    SeeAllLink {
      Link {
        ...StrapiLinkFragment
      }
    }
    SubheadingMobile
    HeaderVariants
    DescriptionMobile
  }
`

export default StrapiHeader
