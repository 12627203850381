// dependencies
import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Grid, makeStyles, useMediaQuery } from '@material-ui/core'
import { styled } from '@mui/material'
import { useInView } from 'react-intersection-observer'
// helpers
import { mapSpacing } from '@helpers/strapi'
import { strapi_trackImpression } from '@helpers/google-tag-manager'
// context
import { useStrapiContext } from '@context/strapiContext'
import { BannerContextProvider } from '@context/bannerContext'
// components
import BannerFinanceButtons from '@templates/strapi-cms/content-types/Banner/BannerFinanceButtons'
import { pageTheme } from '@templates/constants'
import BaseBanner from './BaseBanner'
import Sections from './BannerSections'
import StrapiLink from '../Link'
import Header from '../Header'

const MobileBannerWithImageWrapper = styled('div')(
  ({ theme, iscategorybanner, displaySettings, bannerStyles, isPLPBanner }) => ({
    width: '100%',
    height: isPLPBanner && '100%',
    marginTop: isPLPBanner ? '0' : bannerStyles?.TopMargin || bannerStyles?.BannerMargin || 10,
    marginBottom: bannerStyles?.BottomMargin || bannerStyles?.BannerMargin,
    ...(isPLPBanner ? { padding: '16px' } : {}),
    [theme.breakpoints.down('sm')]: {
      margin: iscategorybanner && 15,
      marginLeft: !iscategorybanner && 0,
      marginRight: !iscategorybanner && 0,
      width: iscategorybanner || (isPLPBanner && 'auto'),
      height: isPLPBanner && '483px',
      maxWidth: '100vw !important',
      display: displaySettings === 'mobile' || displaySettings === 'both' || !displaySettings ? 'block' : 'none',
    },
    [theme.breakpoints.up('sm')]: {
      display: displaySettings === 'desktop' || displaySettings === 'both' || !displaySettings ? 'block' : 'none',
    },
    '& picture img': {
      objectFit: isPLPBanner && 'contain !important',
    },
  }),
)

const GridWrapper = styled('div')(({ theme, displaySettings, bannerStyles, isPLPBanner }) => ({
  marginTop: isPLPBanner ? '0' : bannerStyles?.TopMargin || 10,
  marginBottom: bannerStyles?.BottomMargin,
  ...(isPLPBanner ? { padding: '16px' } : {}),
  [theme.breakpoints.down('sm')]: {
    display: displaySettings === 'mobile' || displaySettings === 'both' || !displaySettings ? 'block' : 'none',
  },
  [theme.breakpoints.up('sm')]: {
    display: displaySettings === 'desktop' || displaySettings === 'both' || !displaySettings ? 'grid' : 'none',
  },
  '& picture img': {
    objectFit: isPLPBanner && 'contain !important',
  },
}))

const SencondaryLinkWrapper = styled('div')(({ theme }) => ({
  marginTop: '10px',
  '& div:has(span[data-testid="mobile-link:text"])': {
    display: 'initial',
  },
  '& span[data-testid="mobile-link:text"]': {
    color: '#003566',
    lineHeight: '18.94px',
    fontSize: '14px',
    fontWeight: '500',
    margin: '10px 0',
  },
  '& .link-arrow-next': {
    top: '15% !important',
    right: '-1.2rem !important',
    fontSize: '1em !important',
    transform: 'translateY(0) !important',
  },
}))

const useStyles = makeStyles(theme => ({
  bannerWrapper: ({ FullWidth, BannerHeight, isPLPBanner, isMobile, horizontalSpacing }) => {
    const widthStyles = FullWidth
      ? {
          minWidth: '100vw',
          position: 'relative',
          marginLeft: 'calc(-50vw + 50%)',
          maxWidth: 'unset',
        }
      : {
          marginLeft: '0 !important',
        }

    const isPLP = isPLPBanner
      ? {
          height: '100%',
          width: '100%',
          // TODO: check for padding in strapi component
        }
      : {}
    return {
      ...widthStyles,
      ...isPLP,
      minHeight: BannerHeight && !isMobile ? `${BannerHeight}px` : 'auto',
      maxWidth: horizontalSpacing?.LeftPadding > 0 || horizontalSpacing?.RightPadding > 0 ? '1440px' : null,
      ...(horizontalSpacing?.LeftPadding > 0 || horizontalSpacing?.RightPadding > 0 ? { marginLeft: 'auto' } : {}),
      ...(horizontalSpacing?.LeftPadding > 0 || horizontalSpacing?.RightPadding > 0 ? { marginRight: 'auto' } : {}),
      paddingLeft: 0,
      paddingRight: 0,
      [theme.breakpoints.up('md')]: {
        paddingLeft: horizontalSpacing?.LeftPadding,
        paddingRight: horizontalSpacing?.RightPadding,
      },
    }
  },
}))

const isHero = (title = '') => {
  const regexp = /hero/gi
  return regexp.test(title)
}

export const shouldBannerHaveImage = (banner, isMobile) => {
  if (banner?.BackgroundImageWithSku?.ImageWithSku) {
    return isMobile && banner?.BackgroundImageWithSku?.ImageWithSku?.MobileImage
      ? banner?.BackgroundImageWithSku?.ImageWithSku?.MobileImage
      : banner?.BackgroundImageWithSku?.ImageWithSku?.Image
  }
  return isMobile && banner?.MobileBackgroundImage ? banner?.MobileBackgroundImage : banner?.BackgroundImage
}

const StrapiBanner = ({
  data,
  contentIndex = null,
  isCategoryBanner = false,
  horizontalSpacing = { LeftPadding: 0, RightPadding: 0 },
  isPLPBanner = false,
}) => {
  const isMobile = useMediaQuery(pageTheme.breakpoints.down('sm'))
  const isIpad = useMediaQuery('(max-width:1025px)')
  const {
    page: { Route },
  } = useStrapiContext()
  const banner = data?.Banner
  const isFinanceBanner = banner?.Title?.includes('Finance')
  const hasImage = shouldBannerHaveImage(banner, isMobile)
  const sections = banner?.BannerSections
  const hasSections = sections && Array.isArray(sections) && sections.length > 0
  const hasBackgroundColor = banner?.BackgroundColor?.ColorHex || null
  const mobileLink = isMobile && banner?.SecondaryLinkMobile?.Link ? banner?.SecondaryLinkMobile?.Link : null
  const context = {
    id: banner?.id,
    Title: banner?.Title,
    isHero: isHero(banner?.Title),
    isPLPBanner,
    trackingData: {
      category: 'promotion',
      action: 'promotion click',
      label: banner?.Title,
      value: contentIndex,
      event: 'ee_promoClick',
      ecommerce: {
        promoClick: {
          promotions: [
            {
              contentGroupTitle: data?.contentGroupTitle,
              contentIndex,
              banner: banner?.Title,
              cid: banner?.id,
              page: Route,
            },
          ],
        },
      },
    },
  }

  const [inViewRef, inView] = useInView({ threshold: 0.2, triggerOnce: true })

  const bannerStyles = {
    TopMargin:
      isMobile && banner?.MobileSpacing?.TopPadding ? banner?.MobileSpacing?.TopPadding : banner?.Spacing?.TopPadding,
    BottomMargin:
      isMobile && banner?.MobileSpacing?.BottomPadding
        ? banner?.MobileSpacing?.BottomPadding
        : banner?.Spacing?.BottomPadding,
  }

  const classes = useStyles({
    FullWidth: banner?.FullWidth,
    BannerHeight: banner?.BannerHeight,
    isPLPBanner,
    isMobile,
    horizontalSpacing: banner?.HorizontalSpacing ?? horizontalSpacing,
  })

  useEffect(() => {
    if (inView) {
      strapi_trackImpression(data, banner?.id, banner?.Title, isPLPBanner)
    }
  }, [data, inView, banner, isPLPBanner])

  if ((hasImage && hasSections) || (hasBackgroundColor && hasSections)) {
    return (
      <BannerContextProvider value={context}>
        <MobileBannerWithImageWrapper
          iscategorybanner={isCategoryBanner}
          displaySettings={banner?.DisplaySettings}
          bannerStyles={bannerStyles}
          isPLPBanner={isPLPBanner}
          className={isFinanceBanner && 'strapi-finance-banner'}
        >
          {banner?.Heading && <Header data={banner?.Heading} />}
          <BaseBanner
            data={data}
            spacing={!isIpad && mapSpacing(banner?.SectionSpacing) / 2}
            data-testid={banner?.testId}
            gridRef={inViewRef}
            className={classes.bannerWrapper}
            bannerHeight={isCategoryBanner ? '60px' : ''}
          >
            <Sections data={sections} hasMainBannerImage={hasImage} bannerHeight={banner?.BannerHeight} />
            {banner.FinanceBanner?.IsFinanceBanner && <BannerFinanceButtons data={banner.FinanceBanner} />}
          </BaseBanner>
          {mobileLink && (
            <SencondaryLinkWrapper>
              <StrapiLink data={mobileLink} arrowNext disableUnderline />
            </SencondaryLinkWrapper>
          )}
        </MobileBannerWithImageWrapper>
      </BannerContextProvider>
    )
  }
  return (
    <BannerContextProvider value={context}>
      {hasImage && (
        <MobileBannerWithImageWrapper
          iscategorybanner={isCategoryBanner}
          displaySettings={banner?.DisplaySettings}
          bannerStyles={bannerStyles}
          isPLPBanner={isPLPBanner}
        >
          {banner?.Heading && <Header data={banner?.Heading} />}
          <BaseBanner data={data} gridRef={inViewRef} data-testid={banner?.testId} className={classes.bannerWrapper} />
          {mobileLink && (
            <SencondaryLinkWrapper>
              <StrapiLink data={mobileLink} arrowNext disableUnderline />
            </SencondaryLinkWrapper>
          )}
        </MobileBannerWithImageWrapper>
      )}
      {hasSections && (
        <GridWrapper displaySettings={banner?.DisplaySettings} bannerStyles={bannerStyles} isPLPBanner={isPLPBanner}>
          {banner?.Heading && <Header data={banner?.Heading} />}
          <Grid
            container
            item
            sm={12}
            spacing={mapSpacing(banner?.SectionSpacing) / 2}
            data-testid={banner?.testId}
            ref={inViewRef}
            className={classes.bannerWrapper}
          >
            <Sections data={sections} />
          </Grid>
          {mobileLink && (
            <SencondaryLinkWrapper>
              <StrapiLink data={mobileLink} arrowNext disableUnderline />
            </SencondaryLinkWrapper>
          )}
        </GridWrapper>
      )}
    </BannerContextProvider>
  )
}

StrapiBanner.propTypes = {
  data: PropTypes.object.isRequired,
  contentIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  horizontalSpacing: PropTypes.object,
  isCategoryBanner: PropTypes.bool,
  isPLPBanner: PropTypes.bool,
}

export default StrapiBanner

export const StrapiPageBannerFragment = graphql`
  fragment StrapiPageBannerFragment on StrapiBanner {
    id
    testId
    Title
    Link {
      Link {
        ...StrapiLinkFragment
      }
    }
    FullWidth
    DisplaySettings
    Heading {
      ...StrapiHeaderFragment
    }
    BannerHeight
    BackgroundColor {
      ColorHex
    }
    BannerMargin
    SectionSpacing
    BackgroundImage {
      ...StrapiImageFragment
    }
    MobileBackgroundImage {
      ...StrapiImageFragment
    }
    BackgroundImageAltText
    FinanceBanner {
      IsFinanceBanner
      ApplyNowBackgroundColor {
        ColorHex
      }
      ApplyNowFontColor {
        ColorHex
      }
      LearnMoreLinkColor {
        ColorHex
      }
    }
    BackgroundImageWithSku {
      ...BaseImageWithSkuFragment
    }
    BannerSections {
      BannerSection {
        id
        __typename
        Content {
          BannerContent {
            id
            Alignment
            VerticalAlignment
            ContentSpacing
            ColumnSize
            Orientation
            Title
            Content {
              ... on BannerBannerImage {
                __typename
                BannerImage {
                  id
                  ImageWithSku {
                    ...BaseImageWithSkuFragment
                  }
                  MobileImage {
                    ...StrapiImageFragment
                  }
                  Image {
                    ...StrapiImageFragment
                  }
                  ColumnSize
                }
              }
              ... on BannerBannerText {
                __typename
                BannerText {
                  id
                  __typename
                  PaddingLeft
                  PaddingRight
                  PaddingTop
                  PaddingBottom
                  BannerText {
                    data {
                      childMarkdownRemark {
                        id
                        html
                      }
                    }
                  }
                  ActivateBannerTextMobile
                  BannerTextMobile {
                    data {
                      childMarkdownRemark {
                        id
                        html
                      }
                    }
                  }
                }
              }
              ... on BannerBannerButton {
                __typename
                BannerButton {
                  ...StrapiBannerButtonFragment
                }
              }
              ... on BannerBannerVideo {
                __typename
                id
                Video {
                  ...StrapiPageVideoFragment
                }
              }
              ... on PageSignUpForm {
                __typename
                ...StrapiPageSignUpFormFragment
              }
              ... on BaseCountdown {
                __typename
                Countdown {
                  ...StrapiCoundownFragment
                }
              }
            }
          }
        }
        BackgroundImageSizing
        BackgroundColor {
          ColorHex
        }
        BackgroundImageWithSku {
          ...BaseImageWithSkuFragment
        }
        BackgroundImage {
          ...StrapiImageFragment
        }
        BorderColor {
          ColorHex
        }
        BorderWidth
        Link {
          Link {
            ...StrapiLinkFragment
          }
        }
        Opacity
        OverlayColor {
          ColorHex
        }
        Title
        ColumnSize
        ContentSpacing
        MobileBackgroundImage {
          ...StrapiImageFragment
        }
      }
    }
    Spacing {
      TopPadding
      BottomPadding
    }
    MobileSpacing {
      TopPadding
      BottomPadding
    }
    HorizontalSpacing {
      LeftPadding
      RightPadding
    }
    SecondaryLinkMobile {
      Link {
        ...StrapiLinkFragment
      }
    }
  }
`
