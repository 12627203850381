import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import StrapiImage from './Image'
import StrapiLink from './Link'

const ImageWithSku = ({ data, height = null, width = null }) => {
  const { testId, Title, Image, MobileImage, Link, ImageAltText } = data?.ImageWithSku || {}
  const imageSizing = data?.imageSizing
  const hasBannerLink = data?.hasBannerLink
  const link = !hasBannerLink && Link?.Link

  const imageData = {
    desktop: Image,
    mobile: MobileImage,
    title: Title,
    alt: ImageAltText,
    imageSizing: imageSizing || null,
  }

  return (
    <StrapiLink data={{ ...link }} data-testid={testId}>
      <StrapiImage data={imageData} height={height} width={width} />
    </StrapiLink>
  )
}

export default ImageWithSku

ImageWithSku.propTypes = {
  data: PropTypes.object,
  height: PropTypes.number,
  width: PropTypes.number,
}

export const BaseImageWithSkuFragment = graphql`
  fragment BaseImageWithSkuFragment on BaseImageWithSku {
    ImageWithSku {
      testId
      Title
      Image {
        ...StrapiImageFragment
      }
      MobileImage {
        ...StrapiImageFragment
      }
      Link {
        Link {
          ...StrapiLinkFragment
        }
      }
      ImageAltText
    }
  }
`
