import React from 'react'
import { Typography, Box } from '@mui/material'
import { string, number, shape } from 'prop-types'
import {
  Heading1Wrapper,
  Heading2Wrapper,
  TitleAndSeeAllWrapper,
  Title,
  SeeAllLinkAndDividerWrapper,
  LinkDivider,
  SeeAllLink,
  Description,
  TitleStyles,
  DescriptionDesktopStyles,
  DescriptionMobileStyles,
} from './HeadingAndSeeAllLink.styles'

const HeadingAndSeeAllLink = ({
  headingStyles,
  title,
  viewAllLink,
  description,
  descriptionMobile,
  subheadingMobile,
  headingVariants,
  testId,
}) => (
  <>
    {headingVariants === 'Heading1' ? (
      <Heading1Wrapper headingStyles={headingStyles} data-testid={testId}>
        <TitleStyles align="center">{title}</TitleStyles>
        {description && (
          <DescriptionDesktopStyles sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Typography align="center">{description}</Typography>
          </DescriptionDesktopStyles>
        )}
        {(descriptionMobile || description) && (
          <DescriptionMobileStyles sx={{ display: { xs: 'block', sm: 'none' } }}>
            <Typography align="center">{descriptionMobile ?? description}</Typography>
          </DescriptionMobileStyles>
        )}
      </Heading1Wrapper>
    ) : (
      <Heading2Wrapper headingStyles={headingStyles} data-testid={testId}>
        <TitleAndSeeAllWrapper headingStyles={headingStyles} hasTitle={!!title}>
          <Title variant="h2" headingStyles={headingStyles}>
            {title}
          </Title>
          {viewAllLink?.Link && (
            <SeeAllLinkAndDividerWrapper>
              {title && <LinkDivider headingStyles={headingStyles}>|</LinkDivider>}
              <SeeAllLink
                data={viewAllLink}
                arrowNext
                headingStyles={headingStyles}
                subheadingMobile={subheadingMobile}
              />
            </SeeAllLinkAndDividerWrapper>
          )}
        </TitleAndSeeAllWrapper>
        {description && (
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Description variant="body2" subheadingMobile={subheadingMobile} isViewAll={!!viewAllLink}>
              {description}
            </Description>
          </Box>
        )}
        {(descriptionMobile || description) && (
          <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
            <Description variant="body2" subheadingMobile={subheadingMobile} isViewAll={!!viewAllLink}>
              {descriptionMobile ?? description}
            </Description>
          </Box>
        )}
      </Heading2Wrapper>
    )}
  </>
)

export default HeadingAndSeeAllLink
HeadingAndSeeAllLink.propTypes = {
  title: string,
  headingStyles: shape({
    fontSize: string,
    fontSizeMobile: string,
    fontWeight: number,
    letterSpacing: string,
    marginBottom: string,
    marginBottomMobile: string,
  }),
  viewAllLink: shape({
    AlternateDescription: string,
    Color: string,
    DisplayText: string,
    ExternalUrl: string,
    InternalUrl: string,
    Target: string,
    Title: string,
    id: string,
    testId: string,
  }),
  description: string,
  descriptionMobile: string,
  subheadingMobile: string,
  headingVariants: string,
  testId: string,
}
