import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { Button, Stack, TextField } from '@mui/material'
import { stateSelectList } from '@helpers/geo-location'
import AddressSuggestionModal from '@components/shared/modals/address-suggestion-modal'
import { fetchAddressFormat, fetchAddressLookup } from '@services/checkout'
import { getAnonymousId, identifyUser } from '@components/integrations/Segment'
import { CheckCircleOutline } from '@mui/icons-material'
import StrapiMarkdown from '../Markdown'

const StyledForm = styled('form')(() => ({
  minWidth: '300px',
  alignItems: 'flex-start',
  width: '75%',
  maxWidth: '500px',
}))

const SuccessMessageContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '635px',
}))

const StyledInput = styled(TextField)(({ theme }) => ({
  borderRadius: '5px',
  backgroundColor: theme.palette.common.white,
}))

const ErrorMessage = styled('span')(({ theme }) => ({
  color: '#e11f21',
  fontSize: '14px',
}))

const InputContainer = styled(Stack)(() => ({
  marginBottom: '1rem',
}))

const ResetAddressButton = styled(Button)(() => ({
  fontSize: '14px',
  lineHeight: 1.15,
  textDecoration: 'underline',
  textTransform: 'capitalize',
  marginTop: '5px',
  padding: 0,
  maxWidth: '90px',
}))

const SubmitButton = styled(Button)(({ textColor, backgroundColor, theme }) => ({
  color: textColor || '#FFFFFF',
  backgroundColor: backgroundColor || theme.palette.primary.dark,
  fontWeight: 'bold',
  fontSize: '2rem',
  height: '50px',
  width: '50%',
}))

const LongForm = ({ data, segmentSubsourceCode, successMessage }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    state: '',
    city: '',
    zipCode: '',
    addressLine1: '',
    addressLine2: '',
  })
  const [formErrors, setFormErrors] = useState({})
  const [userAddress, setUserAddress] = useState({})
  const [suggestedAddress, setSuggestedAddress] = useState({})
  const [formattedAddress, setFormattedAddress] = useState('')
  const [showSuggestionModal, setShowSuggestionModal] = useState(null)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  const {
    ButtonTextColor,
    ButtonBackgroundColor,
    RequireAddressLine1,
    RequireAddressLine2,
    RequireCity,
    RequireFirstName,
    RequireLastName,
    RequireState,
    RequireZipCode,
    ShowAddressLine1,
    ShowAddressLine2,
    ShowCity,
    ShowFirstName,
    ShowLastName,
    ShowState,
    ShowZipCode,
    SegmentSourceCode,
  } = data

  const showAndRequireFullAddress =
    ShowAddressLine1 &&
    RequireAddressLine1 &&
    ShowCity &&
    RequireCity &&
    ShowState &&
    RequireState &&
    ShowZipCode &&
    RequireZipCode

  const buttonTextColor = ButtonTextColor?.ColorHex || ''
  const buttonBackgroundColor = ButtonBackgroundColor?.ColorHex || ''

  const handleChange = e => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
    setFormErrors({
      ...formErrors,
      [name]: '',
    })
    if (name === 'state') {
      setFormData({ ...formData, zipCode: '', [name]: value })
    }
  }

  const resetAddressFields = () => {
    const { state, city, zipCode, addressLine1, addressLine2 } = formData
    if (!addressLine1 && !addressLine2 && !city && !zipCode && !state) return null
    setFormData({ ...formData, state: '', city: '', zipCode: '', addressLine1: '', addressLine2: '' })
    return null
  }

  const handleSubmit = async e => {
    e.preventDefault()
    validateForm()
    const hasErrors = validateForm()
    if (hasErrors) return null
    if (showAndRequireFullAddress) {
      const addressString = `${formData.addressLine1} ${formData.addressLine2 && formData.addressLine2}, ${
        formData.city
      } ${formData.state} ${formData.zipCode}`
      const result = await lookupAddress(addressString)
      if (result?.globalAddressId) {
        setSuggestedAddress(result?.suggestedAddress)
        setFormattedAddress(result?.formatedAddress)
      }
      setShowSuggestionModal(true)
    } else {
      submitEarlyAccess()
    }
    return null
  }

  const lookupAddress = async address => {
    const userAddressObject = {
      address1: formData.addressLine1,
      address2: formData.addressLine2,
      zip: formData.zipCode,
      state: formData.state,
      city: formData.city,
    }

    setUserAddress(userAddressObject)
    const addressSuggestion = await fetchAddressLookup(address)
    const matchConfidence = addressSuggestion.result
    const matchGlobalAddressId = addressSuggestion?.result?.suggestions?.[0]?.global_address_key || ''
    const addressString = addressSuggestion?.result?.suggestions?.[0]?.text || ''
    const fetchedFormattedAddress = (await fetchAddressFormat(matchGlobalAddressId)) || null
    return {
      suggestedAddress: fetchedFormattedAddress,
      formatedAddress: addressString,
      globalAddressId: matchGlobalAddressId,
      confidence: matchConfidence,
    }
  }

  const validateForm = () => {
    const errors = {}
    const zipCodeRegex = /^[0-9]{5}(?:-[0-9]{4})?$/
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    const isValidZip = zipCodeRegex.test(formData.zipCode)
    const isValidEmail = emailRegex.test(formData.email)
    if (RequireFirstName && !formData.firstName) errors.firstName = 'First name is required'
    if (RequireLastName && !formData.lastName) errors.lastName = 'Last name is required'
    if (!formData.email || !isValidEmail) errors.email = 'Please enter a valid email address'
    if (RequireState && !formData.state) errors.state = 'Please select a state'
    if (RequireAddressLine1 && !formData.addressLine1) errors.addressLine1 = 'Address is required'
    if (RequireAddressLine2 && !formData.addressLine2) errors.addressLine2 = 'Address is required'
    if (RequireCity && !formData.city) errors.city = 'City is required'
    if (RequireZipCode && (!formData.zipCode || !isValidZip)) errors.zipCode = 'A valid Zip code is required'
    setFormErrors(errors)
    return Object.values(errors).some(values => !!values)
  }

  const submitEarlyAccess = async () => {
    const params = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email,
      address1: formData.addressLine1,
      address2: formData.addressLine2,
      city: formData.city,
      state: formData.state,
      postal_code: formData.zipCode,
      subsourcecode: segmentSubsourceCode,
      sourcecode: SegmentSourceCode,
      signup: 'true',
      anonymousId: getAnonymousId() || '',
    }
    try {
      await identifyUser(params)
      setupAnalytics(formData.email)
      setShowSuccessMessage(true)
    } catch (error) {
      setFormErrors({
        ...formErrors,
        form:
          error.message === 'Invalid email'
            ? 'Invalid email address.'
            : 'Something went wrong. If you continue to have trouble, please contact support.',
      })
    }
  }

  const getDataTestId = testId => `signup-form-${testId}`

  const setupAnalytics = uEmail => {
    if (window.dataLayer) {
      window.dataLayer.push({ event: 'early_access_sign-up', user: { email: uEmail } })
    }
  }

  /* eslint-disable */
  const getLabel = (description, required) => {
    return (
      <>
        {description} {required && <span style={{ fontSize: 18, color: '#E11F21' }}>*</span>}
      </>
    )
  }
  /* eslint-enable */

  const handleAddressAccept = () => {
    const formDataObject = {
      ...formData,
      addressLine1: suggestedAddress.address1,
      addressLine2: suggestedAddress.address2,
      city: suggestedAddress.city,
      state: suggestedAddress.state,
      zipCode: suggestedAddress.zip,
    }
    setFormData(formDataObject)
    setShowSuggestionModal(false)
    submitEarlyAccess()
  }

  const handleAddressDecline = () => {
    setShowSuggestionModal(false)
    submitEarlyAccess()
  }

  const handleModalclose = () => {
    setShowSuggestionModal(false)
  }

  const getTextFieldProps = (fieldName, label, isRequired, givenId) => {
    const dataTestId = `signup-form-${fieldName}`
    return {
      name: fieldName,
      onChange: handleChange,
      testId: dataTestId,
      label: getLabel(label, isRequired),
      id: givenId,
      className: givenId,
    }
  }

  if (showSuccessMessage) {
    return (
      <SuccessMessageContainer
        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
      >
        <CheckCircleOutline style={{ color: 'green' }} />
        <StrapiMarkdown data={{ Markdown: successMessage }} />
      </SuccessMessageContainer>
    )
  }

  return (
    <StyledForm onSubmit={handleSubmit}>
      {showSuggestionModal && (
        <AddressSuggestionModal
          address={userAddress}
          isModalOpen={showSuggestionModal}
          suggestedAddress={formattedAddress}
          onAccept={handleAddressAccept}
          onDecline={handleAddressDecline}
          onModalClose={handleModalclose}
        />
      )}
      <Stack direction="column">
        {ShowFirstName && (
          <InputContainer direction="column">
            <StyledInput
              {...getTextFieldProps('firstName', 'First Name', RequireFirstName, 'first_name')}
              value={formData.firstName}
              error={formErrors.firstName}
            />
            <ErrorMessage testId={getDataTestId('firstName-error')}>{formErrors.firstName}</ErrorMessage>
          </InputContainer>
        )}
        {ShowLastName && (
          <InputContainer direction="column">
            <StyledInput
              {...getTextFieldProps('lastName', 'Last Name', RequireLastName, 'last_name')}
              value={formData.lastName}
              error={formErrors.lastName}
            />
            <ErrorMessage testId={getDataTestId('lastName-error')}>{formErrors.lastName}</ErrorMessage>
          </InputContainer>
        )}
        <InputContainer direction="column">
          <StyledInput
            {...getTextFieldProps('email', 'Email', true, 'email')}
            error={formErrors.email}
            value={formData.email}
          />
          <ErrorMessage testId={getDataTestId('email-error')}>{formErrors.email}</ErrorMessage>
        </InputContainer>
        {ShowAddressLine1 && (
          <InputContainer direction="column">
            <StyledInput
              {...getTextFieldProps('addressLine1', 'Street Address', RequireAddressLine1, 'address_line1')}
              value={formData.addressLine1}
              error={formErrors.addressLine1}
            />
            <ErrorMessage testId={getDataTestId('address1-error')}>{formErrors.addressLine1}</ErrorMessage>
            <ResetAddressButton onClick={resetAddressFields} testId={getDataTestId('clear-address-button')}>
              Clear Address
            </ResetAddressButton>
          </InputContainer>
        )}
        {ShowAddressLine2 && (
          <InputContainer direction="column">
            <StyledInput
              {...getTextFieldProps('addressLine2', 'Address Line 2', RequireAddressLine2, 'address_line2')}
              value={formData.addressLine2}
              error={formErrors.addressLine2}
            />
            <ErrorMessage testId={getDataTestId('address2-error')}>{formErrors.addressLine2}</ErrorMessage>
          </InputContainer>
        )}
        {ShowCity && (
          <InputContainer direction="column">
            <StyledInput
              {...getTextFieldProps('city', 'City', RequireCity, 'city')}
              value={formData.city}
              error={formErrors.city}
              sx={{ width: '75%' }}
            />
            <ErrorMessage testId={getDataTestId('city-error')}>{formErrors.city}</ErrorMessage>
          </InputContainer>
        )}
        {ShowState && (
          <InputContainer direction="column">
            <StyledInput
              error={formErrors.state}
              fullWidth
              id="state"
              name="state"
              value={formData.state.toUpperCase()}
              label={getLabel('State', RequireState)}
              testId={getDataTestId('state')}
              onChange={handleChange}
              select
              SelectProps={{
                native: true,
              }}
              sx={{ textAlign: 'left', width: 104 }}
            >
              <option aria-label="None" value="" testId={getDataTestId('state-option-list')} />{' '}
              {/* Need this for Safari browser */}
              {stateSelectList.map(st => (
                <option key={`state_dropdown_item_${st}`} testId={getDataTestId('state-option')} value={st}>
                  {st}
                </option>
              ))}
            </StyledInput>
            <ErrorMessage testId={getDataTestId('state-error')}>{formErrors.state}</ErrorMessage>
          </InputContainer>
        )}
        {ShowZipCode && (
          <InputContainer direction="column">
            <StyledInput
              {...getTextFieldProps('zipCode', 'Zip Code', RequireZipCode, 'zipCode')}
              value={formData.zipCode}
              error={formErrors.zipCode}
              sx={{ width: '50%' }}
            />
            <ErrorMessage testId={getDataTestId('zip-error')}>{formErrors.zipCode}</ErrorMessage>
          </InputContainer>
        )}
        <ErrorMessage style={{ marginBottom: '8px' }} testID={getDataTestId('form-error')}>
          {formErrors.form}
        </ErrorMessage>
      </Stack>
      <SubmitButton
        type="submit"
        textColor={buttonTextColor}
        backgroundColor={buttonBackgroundColor}
        testId={getDataTestId('submit-button')}
      >
        ENTER &gt;
      </SubmitButton>
    </StyledForm>
  )
}

export default LongForm

LongForm.propTypes = {
  data: PropTypes.object,
  welcomeMessage: PropTypes.string,
  successMessage: PropTypes.string,
  segmentSubsourceCode: PropTypes.string,
}
