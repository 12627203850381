import React, { useState, useEffect } from 'react'
import { string, object } from 'prop-types'
import { emailSignup, setReset } from '@redux/modules/email-subscribe'
import { useSelector, useDispatch } from 'react-redux'
import { validateEmail, validateZip } from '@helpers/string-helper'
import { useInjectSaga } from '@redux/injectSaga'
import { styled, TextField, css, useMediaQuery } from '@mui/material'
import { useTest } from '@hooks/useTest'
import saga from '@redux/coreSaga'
import loaderLight from '@assets/images/loader-light.svg'
import { getZip } from '@helpers/geo-location'

import StrapiMarkdown from '../Markdown'

export const generateErrorMessage = isEmailValid => {
  if (!isEmailValid) {
    return { message: '*Invalid email address.', id: 'email-error' }
  }
  return {}
}

const StyledInput = styled(TextField)(
  ({ theme }) => css`
    background-color: ${theme.palette.common.white};
    border-radius: 4px;

    .MuiInputBase-root {
      margin: 0 !important;

      &::before,
      &::after {
        border: none;
        border-bottom: none !important;
      }

      &:hover {
        &::before,
        &::after {
          border: none;
          border-bottom: none !important;
        }
      }
    }

    .MuiInputBase-input {
      font-size: 16px;
      line-height: initial;
      padding: 7.5px 12px !important;
      border-radius: inherit;
      border-radius: 4px;
    }

    .Mui-error .MuiInputBase-input {
      padding: 6.5px 12px !important;
      border: 1px solid ${theme.palette.error.border} !important;
    }

    .MuiInputLabel-root {
      transform: translate(12px, 8px) scale(1);
      color: ${theme.palette.secondary.dark};

      &.Mui-focused,
      &.MuiFormLabel-filled {
        transform: translate(4px, -16px) scale(0.75);
        text-transform: uppercase;
        color: ${theme.palette.common.white};
        font-weight: 600;
      }
    }
  `,
)

const StyledButton = styled('button')(({ theme }) => ({
  color: '#07263B',
  backgroundColor: '#ffcb00',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: 'initial',
  borderRadius: '4px',
  width: '100%',
  padding: '8px 12px',
  minHeight: '38px',
  margin: 0,
  outlineOffset: '-1px',
  textTransform: 'uppercase',

  [theme.breakpoints.down('sm')]: {
    gridColumn: 'span 2',
  },

  '& .loader': {
    height: '22px',
  },

  '&:hover': {
    opacity: '0.8',
  },
}))

const StyledFormWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  padding: '32px 0 40px',

  '@media only screen and (min-width: $b-md)': {
    padding: 0,
  },
}))

const StyledLeyend = styled('legend')(({ theme }) => ({
  fontSize: '16px',
  lineHeight: 'initial',
  fontWeight: 500,
  marginBottom: '12px',
  width: '100%',
}))

const StyledForm = styled('form')(({ theme }) => ({
  display: 'grid',
  gap: '16px',
  gridTemplateColumns: '1fr 200px',
  alignItems: 'flex-start',
  width: '800px',
  margin: '0 auto',

  [theme.breakpoints.down('md')]: {
    minWidth: '75vw',
    width: '100%',
  },
}))

const StyledFieldError = styled('p')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: '10px',
  color: '#fff',
  fontWeight: 600,
  padding: '4px 8px',
  backgroundColor: '#eb141f',
  borderRadius: '4px',
  lineHeight: 'initial',
  alignSelf: 'flex-start',
  minHeight: '22px',
}))

const StyledFieldContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  '&.full-line': {
    gridColumn: 'span 2',
  },

  '& input.signup-email, & input.signup-zip': {
    width: '100%',
    fontSize: '16px',
    lineHeight: 'initial',
    color: '#333',
    padding: '8px 12px',
    borderRadius: '4px',
    minHeight: '38px',
    margin: 0,

    '&::placeholder': {
      color: '#333',
      opacity: '0.3',
    },
  },
}))

const StyledStrapiMarkdown = styled(StrapiMarkdown)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
}))

export const EmailSubscribeForm = ({ className, subsourcecode, successMessage, welcomeMessage }) => {
  useInjectSaga({ key: 'email-subscribe', saga, keepAlive: true })
  const { generateTestId } = useTest()
  const error = useSelector(state => state.emailSubscribe.error)
  const success = useSelector(state => state.emailSubscribe.success)
  const loading = useSelector(state => state.emailSubscribe.loading)
  const [isEmailValid, setEmailValid] = useState(true)
  const dispatch = useDispatch()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const [email, setEmail] = useState('')
  const [showSuccessMessage, setSuccessMessage] = useState(false)

  const onFormSubmit = event => {
    if (event && event.preventDefault) event.preventDefault()
    const validEmail = validateEmail(email)
    setEmailValid(validEmail)
    if (validEmail) {
      const zipcode = getZip()
      const validZipcode = validateZip(zipcode)
      const params = validZipcode ? { email, zipcode, subsourcecode } : { email, zipcode: null, subsourcecode }
      dispatch(emailSignup(...Object.values(params)))
      setupAnalytics(email)
      setEmail('')
    }
  }

  const setupAnalytics = uEmail => {
    if (window.dataLayer) {
      window.dataLayer.push({ event: 'footer_email_sign-up', user: { email: uEmail } })
    }
  }

  useEffect(() => {
    if (success) {
      setSuccessMessage(true)
    }
    return () => dispatch(setReset())
  }, [dispatch, success])

  const invalidFields = []
  if (!isEmailValid) invalidFields.push('email')
  const { message, id } = generateErrorMessage(isEmailValid)
  return (
    <StyledFormWrapper
      className={`email-subscribe-form ${className}`}
      data-testid={generateTestId('footer', 'email-subscribe-wrapper')}
    >
      {!showSuccessMessage ? (
        <fieldset>
          {welcomeMessage && (
            <StyledLeyend className="email-subscribe-form__label">
              <StyledStrapiMarkdown gridWrapper={false} data={{ Markdown: welcomeMessage }} />
            </StyledLeyend>
          )}
          <StyledForm
            className="email-subscribe-form__form"
            data-testid={generateTestId('footer', 'email-subscribe-form')}
            onSubmit={e => {
              e.preventDefault()
            }}
          >
            {error && <p className="error">*Unable to sign up for emails.</p>}
            <StyledFieldContainer className={`email-subscribe-form__field-container ${isMobile ? 'full-line' : ''}`}>
              <StyledInput
                value={email}
                onChange={e => setEmail(e.target.value)}
                label="Email Address"
                size="small"
                variant="standard"
                autoComplete="email"
                type="email"
                id="signup_email"
                className="signup_email"
                error={invalidFields?.includes('email')}
              />
              {message && id === 'email-error' && (
                <StyledFieldError
                  className="email-subscribe-form__field-error"
                  data-testid={generateTestId('footer', 'email-subscribe-email-error')}
                >
                  {message}
                </StyledFieldError>
              )}
            </StyledFieldContainer>
            <StyledButton
              type="button"
              value="Sign Up"
              name="signup_btn"
              className="email-subscribe-form__signup-btn"
              id="signup_btn"
              gtm-category="strapi"
              gtm-action="email signup"
              onClick={onFormSubmit}
            >
              {loading ? <img className="loader" alt="Sign Up" src={loaderLight} /> : 'Sign Up'}
            </StyledButton>
          </StyledForm>
        </fieldset>
      ) : (
        <>{successMessage ? <StyledStrapiMarkdown gridWrapper={false} data={{ Markdown: successMessage }} /> : null}</>
      )}
    </StyledFormWrapper>
  )
}

EmailSubscribeForm.propTypes = {
  className: string,
  subsourcecode: string,
  successMessage: object,
  welcomeMessage: object,
}

export default EmailSubscribeForm
