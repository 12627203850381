// dependencies
import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Grid } from '@material-ui/core'
import classNames from 'classnames'
// helpers
import { mapSpacing, toGridBreakpoint } from '@helpers/strapi'
// context
import { useBannerContext } from '@context/bannerContext'

// components
import BaseBanner from './BaseBanner'
import Content from './BannerContent'
import Link from '../Link'
import { shouldBannerHaveImage } from '.'

const useStyles = makeStyles(theme => ({
  contentWrapper: ({ BorderColor, BorderWidth, BackgroundColor, Opacity, OverlayColor, ContentSpacing }) => ({
    position: 'relative',
    borderStyle: BorderColor && BorderWidth ? 'solid' : 'none',
    borderColor: BorderColor?.ColorHex || null,
    borderWidth: BorderWidth || null,
    backgroundColor: BackgroundColor?.ColorHex || null,
    zIndex: 0,
    padding: `${mapSpacing(ContentSpacing) * 4}`,
    [theme.breakpoints.up('sm')]: {
      '&:first-child': {
        paddingLeft: '0',
      },
      '&:last-child': {
        paddingRight: '0',
      },
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 !important',
      padding: '0',
    },
    '&:before':
      Opacity && OverlayColor?.ColorHex
        ? {
            zIndex: '-1',
            content: '""',
            opacity: Opacity || null,
            backgroundColor: OverlayColor?.ColorHex || null,
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            height: '100%',
          }
        : null,
  }),
  contentOnly: ({ mainImageWidth, mainImageHeight, bannerHeight }) => ({
    aspectRatio: !bannerHeight ? mainImageWidth / mainImageHeight : null,
  }),
}))

const SingleSectionBanner = ({ BannerSection = {}, hasMainBannerImage = {}, bannerHeight }) => {
  const { trackingData } = useBannerContext()

  const { BackgroundColor, BorderColor, BorderWidth, Opacity, ContentSpacing, ColumnSize, OverlayColor } = BannerSection
  const hasImage = shouldBannerHaveImage(BannerSection)
  const hasContent = BannerSection?.Content && Array.isArray(BannerSection.Content) && BannerSection.Content.length > 0
  const hasOverlay = BackgroundColor?.ColorHex || null
  const sectionLink = BannerSection?.Link?.Link || BannerSection?.BackgroundImageWithSku?.ImageWithSku?.Link?.Link
  const mainImageWidth = hasMainBannerImage?.width
  const mainImageHeight = hasMainBannerImage?.height

  const linkProps = sectionLink
    ? {
        component: Link,
        data: sectionLink,
        trackingData,
        underline: 'none',
      }
    : {}

  const classes = useStyles({
    BackgroundColor,
    BorderColor,
    BorderWidth,
    Opacity,
    OverlayColor,
    ContentSpacing,
    mainImageWidth,
    mainImageHeight,
    bannerHeight,
  })

  if (hasContent) {
    const ContentComponents = BannerSection.Content.map(sectionContent => {
      const Banner = sectionContent?.BannerContent || {}
      // Need to remove background from section content because it needs to be on the parent element to the content
      const SectionWithoutBackgroundImage = {
        ...BannerSection,
        ...{ BackgroundImage: undefined, MobileBackgroundImage: undefined },
      }
      return <Content key={`${Banner.id}-section`} Banner={Banner} Section={SectionWithoutBackgroundImage} />
    })
    const justify = {
      left: 'flex-start',
      center: 'center',
      right: 'flex-end',
    }
    const align = {
      top: 'flex-start',
      middle: 'center',
      bottom: 'flex-end',
    }
    const Banner = BannerSection.Content[0]?.BannerContent
    const hasFinanceModalContent =
      Banner?.Content?.length > 0 ? Banner.Content[0]?.BannerButton?.Link?.Link?.ShowLearnMoreFinanceModal : undefined
    const direction = Banner?.Orientation || 'row'
    // content and image
    if (hasImage) {
      return (
        <BaseBanner
          data={{ Banner: BannerSection }}
          className={classes.contentWrapper}
          sm={toGridBreakpoint(ColumnSize)}
          alignItems={
            direction === 'row' ? align[Banner?.VerticalAlignment] || null : justify[Banner?.Alignment] || null
          }
        >
          {hasOverlay && <div className={classes.overlay} />}
          {ContentComponents}
        </BaseBanner>
      )
    }
    // content only
    return (
      <Grid
        container
        className={classNames(classes.contentWrapper, { [classes.contentOnly]: hasFinanceModalContent })}
        key={BannerSection.id}
        item
        sm={toGridBreakpoint(ColumnSize)}
        {...linkProps}
      >
        {hasOverlay && <div className={classes.overlay} />}
        {ContentComponents}
      </Grid>
    )
  }
  // image only
  if (hasImage) {
    return (
      <BaseBanner
        data={{ Banner: BannerSection }}
        className={classes.contentWrapper}
        sm={toGridBreakpoint(ColumnSize)}
      />
    )
  }

  return null
}

SingleSectionBanner.propTypes = {
  BannerSection: PropTypes.object.isRequired,
  hasMainBannerImage: PropTypes.object,
  bannerHeight: PropTypes.number,
}

export default SingleSectionBanner
