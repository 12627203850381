import React from 'react'
import Modal from 'react-modal'
import styled from 'styled-components'
import { bool, func, object, string } from 'prop-types'
import { breakPoints, colors, fonts } from '@constants/styles'

const ModalWrapper = styled(Modal)`
  .address-suggestion-modal {
    .modal-content {
      background-color: ${colors.white};
      border: solid ${colors.grey} 0.4rem;
      width: 90%;
      position: fixed;
      right: auto;
      bottom: auto;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      max-width: 45rem;
      padding: 0.5rem;
      @media only screen and (min-device-width: 374px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
        height: 90%;
        max-height: 90% !important;
      }
      .modal-title {
        font-size: ${fonts.txtMedium};
        font-weight: 600;
        display: block;
        margin-bottom: 0.5rem;
      }
      .grid-x {
        background: ${colors.lighterGrey};
        padding: 1rem;
        margin-top: 0.5rem;
        .medium-6 {
          padding: 0 0.75rem;
          .address {
            display: block;
          }
        }
      }
      .accept-btn,
      .keep-btn {
        width: 100%;
        color: ${colors.white};
        height: 2.5rem;
        font-weight: 600;
        font-size: ${fonts.txtSmall};
        margin-top: 1rem;
        &:hover {
          opacity: 0.85;
        }
      }
      .accept-btn {
        background: ${colors.red};
        @media only screen and (max-width: ${breakPoints.small}) {
          margin-bottom: 1rem;
        }
      }
      .keep-btn {
        background: ${colors.primary};
      }
    }
    .go-back {
      padding-top: 1rem;
      color: ${colors.primary};
      font-size: ${fonts.txtXSmall};
      margin-left: 0.3rem;
      &:hover {
        text-decoration: underline;
      }
      @media only screen and (min-width: ${breakPoints.medium}) {
        font-size: ${fonts.txtSmall};
      }
    }
    .close-modal {
      position: absolute;
      top: -2em;
      right: -1.75em;
      background: ${colors.primary};
      width: 4em;
      height: 4em;
      border-radius: 50%;
      @media only screen and (min-device-width: 374px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
        top: -1.5em;
        right: -1.5em;
      }
      @media only screen and (max-width: ${breakPoints.small}) {
        top: -1.4em;
        right: -1.1em;
        width: 3.5em;
        height: 3.5em;
      }
      &:hover {
        opacity: 0.85;
      }
      .close {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 3em;
        height: 3em;
        margin: auto;
        background-size: 100% !important;
      }
    }
  }
`
if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#___gatsby')

const AddressSuggestionModal = ({ isModalOpen, address, suggestedAddress, onModalClose, onAccept, onDecline }) => {
  const orderAddress = [
    `${address.address1} ${address.address2 ? `${address.address2} ` : ''}`,
    `${address.city} ${address.state.toUpperCase()} ${address.zip}`,
  ]
  const splitSuggestedAddress = suggestedAddress && suggestedAddress.split(',')
  return (
    <ModalWrapper
      isOpen={isModalOpen}
      onRequestClose={onModalClose}
      contentLabel="Address Suggestion Modal"
      className="address-suggestion-modal"
      overlayClassName="modal-overlay"
    >
      <div className="modal-content">
        <span className="modal-title" role="heading" aria-level="1">
          We are having trouble verifying the address as it was entered.
        </span>
        <div className="grid-x">
          {suggestedAddress && (
            <div className="small-12 medium-6">
              <span className="modal-title" role="heading" aria-level="2">
                Suggested address:
              </span>
              <span className="address">{splitSuggestedAddress[0]}</span>
              <span className="address">{splitSuggestedAddress[1]}</span>
              <button
                type="button"
                className="accept-btn"
                tabIndex="0"
                value="accept address suggestion"
                aria-label="accept address suggestion"
                onClick={onAccept}
              >
                ACCEPT ADDRESS SUGGESTION
              </button>
            </div>
          )}
          <div className="small-12 medium-6">
            <span className="modal-title" role="heading" aria-level="2">
              Address as entered:
            </span>
            <span className="address">{orderAddress[0]}</span>
            <span className="address">{orderAddress[1]}</span>
            <button
              type="button"
              className="keep-btn"
              tabIndex="0"
              value="keep address as entered"
              aria-label="keep address as entered"
              onClick={onDecline}
            >
              KEEP ADDRESS AS ENTERED
            </button>
          </div>
        </div>
        <button type="button" className="go-back" tabIndex="0" value="Close" aria-label="Close" onClick={onModalClose}>
          {'< GO BACK AND EDIT THE ADDRESS PROVIDED'}
        </button>
        <button
          type="button"
          className="close-modal"
          tabIndex="0"
          value="Close"
          aria-label="Close"
          onClick={onModalClose}
        >
          <img
            className="icon close"
            alt="close icon"
            src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23FFFFFF' d='M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z' /%3E%3C/svg%3E"
          />
        </button>
      </div>
    </ModalWrapper>
  )
}

AddressSuggestionModal.propTypes = {
  isModalOpen: bool,
  address: object,
  suggestedAddress: string,
  onModalClose: func,
  onAccept: func,
  onDecline: func,
}

export default AddressSuggestionModal
