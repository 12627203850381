// dependencies
import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles, Button } from '@material-ui/core'
import { graphql } from 'gatsby'
// context
import { useBannerContext } from '@context/bannerContext'
// components
import StrapiButton from '../Button'

const useStyles = makeStyles(() => ({
  wrapper: {
    width: 'auto',
    maxWidth: 'unset',
  },
}))

/**
 * Buttons font sizes in REM's
 */
const sizes = {
  small: 0.9,
  medium: 1.1,
  large: 1.4,
}

/**
 * Button icon positions
 */
const position = {
  left: 'row',
  top: 'column',
  bottom: 'column-reverse',
  right: 'row-reverse',
}

const paddings = {
  small: '10px',
  medium: '10px 20px',
  large: '10px 40px',
}

const StrapiBannerButton = ({ data, ...wrapperProps }) => {
  const { trackingData, isPLPBanner } = useBannerContext()
  const classes = useStyles()
  const styles = {
    button: {
      backgroundColor: data?.ButtonBackgroundColor?.ColorHex || null,
      backgroundColorHover: data?.ButtonBackgroundColorHover?.ColorHex || null,
      borderColor: data?.ButtonBorderColor?.ColorHex || null,
      color: data?.FontColor?.ColorHex || null,
      borderWidth: data?.ButtonBorderWidth || null,
      borderRadius: data?.ButtonBorderRadius || null,
      fontSize: `${sizes[data?.Size || 'medium']}rem`,
      padding: paddings[data?.Size || 'medium'],
      flexDirection: position[data?.ButtonIconPosition || 'left'],
    },
    learnMore: {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    caption: {
      textAlign: 'center',
      marginTop: 5,
      fontSize: '0.875rem',
      color: data?.CaptionFontColor?.ColorHex,
    },
  }

  const buttonData = {
    id: data?.id,
    Icon: data?.ButtonIcon?.[0] ?? data?.ButtonIcon,
    DisplayIcon: Boolean(data?.ButtonIcon?.url ?? data?.ButtonIcon?.[0]?.url),
    Text: data?.Text,
    DisplayText: Boolean(data?.Text),
    Link: data?.Link,
    FinanceButton: Boolean(data?.FinanceButton),
  }

  const trackInfo = buttonData?.FinanceButton
    ? {
        event: 'ee_applyNowClick',
        isPLPBanner,
      }
    : trackingData

  return (
    <Grid container item md={4} className={classes.wrapper} {...wrapperProps} direction="column">
      <Grid item xs={12}>
        <StrapiButton data={buttonData} styles={styles.button} trackingData={trackInfo} isBannerButton />
        {data?.Caption && <div style={styles.caption}>{data.Caption}</div>}
      </Grid>
    </Grid>
  )
}

StrapiBannerButton.propTypes = {
  data: PropTypes.object.isRequired,
}

export default StrapiBannerButton

export const StrapiBannerButtonFragment = graphql`
  fragment StrapiBannerButtonFragment on StrapiBannerButton {
    __typename
    ButtonBackgroundColor {
      ColorHex
    }
    ButtonBackgroundColorHover {
      ColorHex
    }
    ButtonBorderColor {
      ColorHex
    }
    Link {
      Link {
        ...StrapiLinkFragment
      }
    }
    ButtonIcon {
      ...StrapiImageFragment
    }
    ButtonBorderWidth
    Size
    FontColor {
      ColorHex
    }
    FinanceButton
    ButtonIconPosition
    Text
    Title
    id
    ButtonBorderRadius
    Caption
    CaptionFontColor {
      ColorHex
    }
  }
`
