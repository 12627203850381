import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { styled } from '@mui/material/styles'
import Markdown from './Markdown'

const BannerTextWrapper = styled('div')(({ theme, backgroundColorHex, align, justifyContent, textAlign, height }) => ({
  backgroundColor: backgroundColorHex || 'transparent',
  display: 'flex',
  height: height || '100%',
  width: '100%',
  '& > *': {
    alignItems: align || 'center',
    display: 'flex',
    justifyContent: justifyContent || 'center',
    height: '100%',
    width: '100%',
    '& > *': {
      margin: 0,
      textAlign: textAlign || 'center',
    },
  },
}))

const StrapiBannerText = ({ data, Alignment, VerticalAlignment }) => {
  const {
    testId,
    BannerText,
    BannerTextMobile,
    BannerTextWithNoSections,
    PaddingBottom,
    PaddingLeft,
    PaddingRight,
    PaddingTop,
    ActivateBannerTextMobile,
  } = data?.BannerText || {}

  const justify = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
  }

  const align = {
    top: 'flex-start',
    middle: 'center',
    bottom: 'flex-end',
    expanded: 'space-between',
  }

  const textAlign = {
    left: 'start',
    center: 'center',
    right: 'end',
  }

  const backgroundColorHex = BannerTextWithNoSections?.BackgroundColor?.ColorHex
  const bannerAlignment = align[BannerTextWithNoSections?.VerticalAlignment || VerticalAlignment]
  const bannerJustifyContent = justify[BannerTextWithNoSections?.Alignment || Alignment]
  const textAlignment = textAlign[BannerTextWithNoSections?.Alignment || Alignment]
  const bannerHeight = BannerTextWithNoSections?.Height

  return (
    <BannerTextWrapper
      data-testid={testId}
      backgroundColorHex={backgroundColorHex}
      align={bannerAlignment}
      justifyContent={bannerJustifyContent}
      textAlign={textAlignment}
      height={bannerHeight}
    >
      <Markdown
        data={{
          Markdown: { Markdown: BannerText, id: testId },
          PaddingTop: PaddingTop || 15,
          PaddingBottom: PaddingBottom || 15,
          PaddingLeft: PaddingLeft || 15,
          PaddingRight: PaddingRight || 15,
          MarkdownMobile: ActivateBannerTextMobile && {
            Markdown: BannerTextMobile,
            id: testId,
          },
        }}
      />
    </BannerTextWrapper>
  )
}

export default StrapiBannerText

StrapiBannerText.propTypes = {
  data: PropTypes.object,
  Alignment: PropTypes.string,
  VerticalAlignment: PropTypes.string,
}

export const StrapiBannerTextFragment = graphql`
  fragment StrapiBannerTextFragment on BannerBannerText {
    BannerText {
      Title
      testId
      BannerText {
        data {
          childMarkdownRemark {
            html
          }
        }
      }
      BannerTextMobile {
        data {
          childMarkdownRemark {
            html
          }
        }
      }
      BannerTextWithNoSections {
        Alignment
        BackgroundColor {
          ColorHex
        }
        Height
        VerticalAlignment
      }
      PaddingBottom
      PaddingTop
      PaddingLeft
      PaddingRight
      ActivateBannerTextMobile
    }
  }
`
